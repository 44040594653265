<template>
  <div class="do_keep">
    <back-step
      :goList="[{
        url: '/cases',
        name: $t('casesDetail.casesChildren.common.bl')
      }, {
        url: '/cases/detail',
        name: $t('casesDetail.casesChildren.common.blxq')
      }]"
      :currentTxt="$t('casesDetail.casesChildren.casesTransfer.zrbl')"
    />
    <div class="plan_head">
      <span class="plan_h_strong">{{$t('casesDetail.casesChildren.casesTransfer.blh')}}：{{casesInfo.caseNumber}}</span>
      <span class="plan_h_sp">{{casesInfo.clinicName}}</span>
      <span class="plan_h_sp">{{casesInfo.productName}}</span>
    </div>
    <div class="cases_d_box">
      <div class="cases_d_con">
        <div class="cases_d_left">
          <cases-user-msg v-if="commonCaseDetail.state !== 'loading'" :caseDetail="commonCaseDetail"/>
        </div>
        <div class="cases_d_right">
          <ul class="braces_from">
            <li class="f_single">
              <div :style="language=== 'en_US' ? 'width: 2rem;' : ''" class="f_single_left">
                {{$t('casesDetail.casesChildren.casesTransfer.zrzs')}}:
              </div>
              <div class="f_single_right">
                <el-select v-model="transferConfig.toClinicId" @change="changeClinic"
                           clearable :placeholder="$t('casesDetail.casesChildren.common.qxz')">
                  <el-option
                    v-for="item in clinicDataList"
                    :key="item.id"
                    :label="`${item.name}_${item.enName ? item.enName : ''}`"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li class="f_single" v-show="doctorList.length">
              <div :style="language=== 'en_US' ? 'width: 2rem;' : ''" class="f_single_left">
                {{$t('casesDetail.casesChildren.casesTransfer.zrys')}}:
              </div>
              <div class="f_single_right">
                <el-select v-model="transferConfig.toDoctorId" clearable :placeholder="$t('casesDetail.casesChildren.common.qxz')">
                  <el-option
                    v-for="item in doctorList"
                    :key="item.id"
                    :label="`${item.realName}_${item.mobile ? item.mobile : ''}`"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </li>
            <li class="f_single">
              <div :style="language=== 'en_US' ? 'width: 2rem;' : ''" class="f_single_left">
                {{$t('casesDetail.casesChildren.casesTransfer.zrly')}}:
              </div>
              <div class="f_single_right">
                <div class="right_area">
                  <textarea v-model="transferConfig.reason"></textarea>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="cases_d_foo">
        <span class="main_theme_btn_gary main_big_btn" @click="goBack">{{$t('casesDetail.casesChildren.common.fh')}}</span>
        <span class="main_theme_color_btn main_big_btn" @click="restartCases">{{$t('casesDetail.casesChildren.common.qr')}}</span>
      </div>
    </div>
    <confirm-modal
      :confirmObj="{
        tipText: $t('casesDetail.casesChildren.common.qrjx'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('casesDetail.casesChildren.common.qd')
      }"
      @confirmSubmit="confirmSubmit"
      @confirmHide="confirmHide"
      v-show="isShow"
    />
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../childrenPublic/backStep';
  import CasesUserMsg from '../childrenPublic/casesUserMsg';
  import FullLoading from 'components/full-loading/full-loading';
  import ConfirmModal from 'components/confirm-modal/confirm';
  import {addCaseTransferRecord, clinicList, clinicDoctorList} from 'common/api/cases';
  import {notifyMsg} from 'common/js/util';
  import {mapState,mapActions,mapGetters} from 'vuex';

  export default {
    data() {
      return {
        isLoading: false,
        isShow: false,
        casesInfo: {},
        transferConfig: {
          toClinicId: '',
          toDoctorId: '',
          reason: ''
        },
        clinicDataList: [],
        doctorList: []
      }
    },
    computed:{
      ...mapGetters({
        'commonCaseDetail': 'getCommonCaseDetail',//病例详情
      }),
      ...mapState({
        language: state => state.language,
      }),
      routeQueryCaseId(){
        return this.$route.query.caseId
      },
      routeQueryCurePlanId(){
        return this.$route.query.curePlanId
      }
    },
    methods: {
      ...mapActions({
        'getCommonCaseDetail' : 'actionGetCommonCaseDetail',//病例详情
      }),
      goBack() {
        window.history.go(-1);
      },
      goPage(url) {
        this.$router.push(url);
      },
      changeClinic() {
        this.transferConfig.toDoctorId = '';
        clinicDoctorList({
          clinicId: this.transferConfig.toClinicId,
          status: '1'
        }).then(data => {
          this.doctorList = data.map(item => {
            const {doctorInfo = {}, doctorId} = item;
            return {
              id: doctorId,
              realName: doctorInfo.realName,
              mobile: doctorInfo.mobile
            };
          });
        });
      },
      restartCases() {
        const {toClinicId, toDoctorId, reason} = this.transferConfig;
        if(!toClinicId || !reason) {
          return notifyMsg(this, 'warning', this.$t('casesDetail.casesChildren.casesTransfer.qsrwz'), 2500);
        }else if(!toDoctorId) {
          return notifyMsg(this, 'warning', this.$t('casesDetail.casesChildren.casesTransfer.gzswys'), 2500);
        }
        this.isShow = true;
      },
      confirmSubmit() {
        const id = this.$route.query.caseId;
        if(id) {
          this.isLoading = true;
          addCaseTransferRecord({
            caseId: id,
            ...this.transferConfig
          }).then(() => {
            this.isShow = false;
            this.isLoading = false;
            notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.common.czcg')).then(() => {
              this.goPage(`/cases`);
            });
          }).catch(() => {
            this.isLoading = false;
            this.isShow = false;
          })
        }else {
          notifyMsg(this, 'error', this.$t('casesDetail.casesChildren.common.czsb'));
        }
      },
      confirmHide() {
        this.isShow = false;
      }
    },
    components: {
      BackStep,
      CasesUserMsg,
      FullLoading,
      ConfirmModal
    },
    created() {
      document.title = this.$t('casesDetail.casesChildren.casesTransfer.zrbl');
      const casesId = this.routeQueryCaseId;
      const curePlanId = this.routeQueryCurePlanId;
      this.getCommonCaseDetail(casesId).then((data) => {
        const {productName, clinicName, caseNumber} = data;
        this.casesInfo = {
          caseNumber,
          clinicName,
          productName
        };
      });
      clinicList().then(data => {
        this.clinicDataList = data.map(item => {
          const {clinicInfo = {}} = item;
          return {
            id: clinicInfo.id,
            name: clinicInfo.name,
            enName: clinicInfo.enName
          };
        });
      });
    },
  }
</script>

<style scoped lang="scss">
  .do_keep{
    width: 13.44rem;
    margin: 0 auto;
    color: $main_theme_color_333;
    font-size: 0.16rem;
    .plan_head{
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;
      .plan_h_strong{
        font-size: 0.22rem;
        font-weight: bold;
      }
      .plan_h_sp{
        font-size: 0.18rem;
        padding-left: 0.1rem;
        border-left: 0.01rem solid #B5B5B5;
        margin-left: 0.3rem;
      }
    }
    .cases_d_box{
      background-color: #fff;
      border-radius: 0.1rem;
      padding: 0.67rem 1rem 0.62rem;
      .cases_d_con{
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.67rem;
        .braces_from {
          padding-top: 0.12rem;
          .f_single {
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;
            font-size: 0.14rem;
            .f_single_left {
              margin-right: 0.22rem;
              font-size: 0.14rem;
              width: 0.92rem;
              text-align: right;
              white-space: nowrap;
              span {
                color: #FF6A08;
              }
            }
            .f_single_right {
              border-radius: 0.02rem;
              min-height: 0.42rem;
              color: #333;
              font-size: 0.14rem;
              display: flex;
              align-items: center;
              flex: 1;
              select {
                width: 3rem;
                height: 0.42rem;
                border: none;
                background-color: #F4F4F4;
                padding: 0.1rem 0.19rem;
                box-sizing: border-box;
              }
              .right_area{
                background-color: #F4F4F4;
                padding: 0.1rem;
                height: 1.14rem;
                border-radius: 0.08rem;
                width: 3rem;
                box-sizing: border-box;
                textarea{
                  width: 100%;
                  height: 100%;
                  border: none;
                  outline: none;
                }
              }
              .single_r_p{
                display: flex;
                align-items: center;
                color: $main_theme_color;
                margin-left: 0.19rem;
                .r_p_icon{
                  display: inline-block;
                  width: 0.14rem;
                  height: 0.14rem;
                  background-size: 100% 100%;
                  background-image: url("/static/images/web_scanning.png");
                }
              }
            }
          }
        }
      }
      .cases_d_foo{
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
</style>
